import { AuthMsal } from "@iamexperiences/react-auth";
import { IAuth } from "@iamexperiences/react-auth/lib/core";
import { Logger, LogLevel, UserAgentApplication } from "msal";
import configuration from "./configuration/index";

const msal = new UserAgentApplication({
  auth: {
    clientId: configuration.clientId as string,
    authority: configuration.authority as string,
  },
  system: {
    logger: new Logger(
      (level, message, _isPii) => console.log(`${level} ${message}`),
      {
        level: LogLevel.Error,
        piiLoggingEnabled: false,
        correlationId: "",
      }
    ),
  },
});

export const auth: IAuth = new AuthMsal({
  clientId: configuration.clientId as string,
  authority: configuration.authority as string,
  msalFactory: () => msal,
});
