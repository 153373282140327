const configuration = {
  tenantId: process.env.REACT_APP_TENANT_ID,
  clientId: process.env.REACT_APP_CLIENT_ID,
  authority: process.env.REACT_APP_AUTHORITY,
  userGroups: process.env.REACT_APP_USER_GROUPS.split(","),
  adminGroups: process.env.REACT_APP_ADMIN_GROUPS.split(","),
  debounce: process.env.REACT_APP_DEBOUNCE,
  toasttimeout: process.env.REACT_APP_TOASTERTIMEOUT,
  azureSightResource: process.env.REACT_APP_AZURESIGHT_RESOURCE,
  azureSightResources: process.env.REACT_APP_AZURESIGHT_RESOURCE.split(/\s+/)
    .map((value) => value.trim())
    .filter((value) => !!value),
  azureSightAPI: process.env.REACT_APP_AZURESIGHT_API,
  azureSightAdminAPI: process.env.REACT_APP_AZURESIGHT_ADMIN_API,
  applicationInsightsKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
  irisIngestorBaseUrl: process.env.REACT_APP_IRIS_INGESTOR_BASE_URL,
  validEmailDomains: process.env.REACT_APP_VALID_EMAIL_DOMAINS.split(",")
};

export default configuration;
