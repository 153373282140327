import React from "react";
import { CCol, CRow, CContainer } from "@coreui/react";
import VError from "verror";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, verrorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
      verrorInfo: VError.info(error),
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path

      return (
        <div className="c-app c-default-layout flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md="6">
                <span className="clearfix">
                  <h1 className="float-left display-3 mr-4">
                    {this.state.verrorInfo.code || ""}
                  </h1>
                  <h4 className="pt-3">Houston, we have a problem!</h4>
                  <p className="text-muted float-left">
                    {this.state.error && this.state.error.name}
                  </p>
                </span>
              </CCol>
            </CRow>
            <CRow className="justify-content-center">
              <CCol md="6">
                <details style={{ whiteSpace: "pre-wrap" }}>
                  {this.state.error && this.state.error.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
                </details>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      );
      // return (
      //   <div>
      //     <h2>Something went wrong.</h2>
      //     <details style={{ whiteSpace: "pre-wrap" }}>
      //       {this.state.error && this.state.error.toString()}
      //       <br />
      //       {this.state.errorInfo.componentStack}
      //     </details>
      //   </div>
      // );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
