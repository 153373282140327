import React from "react";
import { ToastState } from "../../recoil/atoms/Toast";
import { useRecoilState } from "recoil";
import Toast from "react-bootstrap/Toast";
import "../../styles/toaster.css";
import configuration from "../../configuration/index";

const Toaster = () => {
  const [toasts, setToasts] = useRecoilState(ToastState);

  const onClose = (toast) => {
    const index = toasts.findIndex((t) => t === toast);
    const newToasts = removeItemAtIndex(toasts, index);
    setToasts(newToasts);
  };

  function removeItemAtIndex(arr, index) {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
  }

  const toastBasestyle = {
    borderRadius: 10,
    maxWidth: 450,
    height: 90,
    width: 375,
    paddingBottom: 5,
    maxHeight: 100,
  };

  return (
    <>
      <div className="toaster-area">
        {toasts.map((toast) => {
          return (
            <Toast
              key={Math.floor(Math.random() * Math.floor(1000))}
              style={toastBasestyle}
              onClose={() => onClose(toast)}
              delay={configuration.toasttimeout}
              autohide
            >
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                />
                <strong className="mr-auto">{toast.header}</strong>
              </Toast.Header>
              <Toast.Body>{toast.body}</Toast.Body>
            </Toast>
          );
        })}
      </div>
    </>
  );
};

export default Toaster;
